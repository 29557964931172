<template>
    <div class="rb-view">
        <div>
            <div>
              <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                <el-row>
                  <el-col :span="4"><el-form-item label="ID:"><el-input clearable size="mini" v-model="search_option.id"></el-input></el-form-item></el-col>
                  <el-col :span="4"><el-form-item label="课程名称:"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item></el-col>
                  <el-col :span="12">
                    <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                    <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onCreateTap">新增</el-button>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border style="width:100%;">
                  <el-table-column label="ID" prop="id" width="150"></el-table-column>
                  <el-table-column label="课程名称" prop="title"></el-table-column>
                  <el-table-column label="图片展示" prop="cover">
                    <template #default="scope">
                      <el-image :src="imgHost+scope.row.cover" :preview-src-list="[imgHost+scope.row.cover]" style="max-height: 80px;height: 80px"></el-image>
                    </template>
                  </el-table-column>
                  <el-table-column label="人数上限" prop="people_limit_up" align="right" width="72"></el-table-column>
                  <el-table-column label="排序" prop="sort" align="right" width="80"></el-table-column>
                  <el-table-column label="操作" width="120">
                        <template #default="scope">
                            <el-button type="text" @click="onEditTap(scope.row)" size="mini">编辑</el-button>
                            <el-button type="text" @click="onRemoveTap(scope.row)" size="mini" style="color:#F56C6C;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogFormVisible" :title="dialogFormTitle" @close="onTellDialogClose" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <fitness-detail :action="dialogFormAction" :row-id="dialogFormRowId" @onTellDialogClose="onTellDialogClose"></fitness-detail>
        </el-dialog>
    </div>
</template>

<script>
    import FitnessDetail from './fitness-detail'
    import {getList,remove} from "@/api/vstar/fitness";
    import {getParams} from "@/api/vstar/param";
    const param_list = ['gender']
    export default {
        name: "fitness-list",
        components:{FitnessDetail},
        data(){
            return{
                imgHost: process.env.VUE_APP_BASE_API,

                dialogFormTitle:'',
                dialogFormVisible:false,
                dialogFormRowId:0,
                dialogFormAction:'',//create view edit

                loading:false,
                search_form_name:'fitness_search',
                page_option:{page:1,size:10,total:0},
                search_option:{type:'all',code:''},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                this.initParams().then(()=>{
                    this.getPageList({page:1,size:this.page_option.size},Object.assign({},this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'en').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key]=pm.value
                                tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt,searchOpt){
                this.loading = true;
                getList(pageOpt.page,pageOpt.size,searchOpt).then(resp=>{
                    this.loading=false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(()=>{
                    this.loading = false;
                })
            },
            handleSearchTap(){
                let searchOpt = Object.assign({},this.search_option)
                this.getPageList({page:1,size:this.page_option.size},searchOpt)
            },
            handleSearchClear(formName){
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize){
                this.getPageList({page:1,size:pageSize},this.search_option);
            },
            handleCurrentChange(pageIndex){
                this.getPageList({page:pageIndex,size:this.page_option.size},this.search_option)
            },
            onViewTap(row){
                console.log('onViewTap:',row)
                this.dialogFormRowId=row.id
                this.dialogFormAction='view'
                this.dialogFormVisible=true
            },
            onEditTap(row){
                console.log('onEditTap:',row)
                this.dialogFormAction='edit'
                this.dialogFormRowId=row.id
                this.dialogFormVisible=true
            },
            onCreateTap(){
                console.log('onCreateTap')
                this.dialogFormRowId=0;
                this.dialogFormAction='add'
                this.dialogFormVisible=true
            },
            onRemoveTap(row){
                this.$messageBox.confirm('确定删除当前数据?','提示',{cancelButtonText:'取消',confirmButtonText:'确定'}).then(()=>{
                    remove(row.id).finally(()=>{this.reloadPage()})
                })

            },
            onTellDialogClose(){
                this.dialogFormVisible=false;
                this.dialogFormRowId=0;
                this.reloadPage()
            },
        }
    }
</script>

<style scoped>

</style>
